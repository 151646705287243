exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-deletion-tsx": () => import("./../../../src/pages/account/deletion.tsx" /* webpackChunkName: "component---src-pages-account-deletion-tsx" */),
  "component---src-pages-account-trial-ended-tsx": () => import("./../../../src/pages/account/trial/ended.tsx" /* webpackChunkName: "component---src-pages-account-trial-ended-tsx" */),
  "component---src-pages-alternative-allstacks-tsx": () => import("./../../../src/pages/alternative/allstacks.tsx" /* webpackChunkName: "component---src-pages-alternative-allstacks-tsx" */),
  "component---src-pages-alternative-athenian-tsx": () => import("./../../../src/pages/alternative/athenian.tsx" /* webpackChunkName: "component---src-pages-alternative-athenian-tsx" */),
  "component---src-pages-alternative-blueoptima-tsx": () => import("./../../../src/pages/alternative/blueoptima.tsx" /* webpackChunkName: "component---src-pages-alternative-blueoptima-tsx" */),
  "component---src-pages-alternative-code-climate-velocity-tsx": () => import("./../../../src/pages/alternative/code-climate-velocity.tsx" /* webpackChunkName: "component---src-pages-alternative-code-climate-velocity-tsx" */),
  "component---src-pages-alternative-dx-tsx": () => import("./../../../src/pages/alternative/dx.tsx" /* webpackChunkName: "component---src-pages-alternative-dx-tsx" */),
  "component---src-pages-alternative-faros-ai-tsx": () => import("./../../../src/pages/alternative/faros-ai.tsx" /* webpackChunkName: "component---src-pages-alternative-faros-ai-tsx" */),
  "component---src-pages-alternative-haystack-tsx": () => import("./../../../src/pages/alternative/haystack.tsx" /* webpackChunkName: "component---src-pages-alternative-haystack-tsx" */),
  "component---src-pages-alternative-jellyfish-tsx": () => import("./../../../src/pages/alternative/jellyfish.tsx" /* webpackChunkName: "component---src-pages-alternative-jellyfish-tsx" */),
  "component---src-pages-alternative-linearb-tsx": () => import("./../../../src/pages/alternative/linearb.tsx" /* webpackChunkName: "component---src-pages-alternative-linearb-tsx" */),
  "component---src-pages-alternative-okayhq-tsx": () => import("./../../../src/pages/alternative/okayhq.tsx" /* webpackChunkName: "component---src-pages-alternative-okayhq-tsx" */),
  "component---src-pages-alternative-pluralsight-flow-gitprime-tsx": () => import("./../../../src/pages/alternative/pluralsight-flow-gitprime.tsx" /* webpackChunkName: "component---src-pages-alternative-pluralsight-flow-gitprime-tsx" */),
  "component---src-pages-alternative-pull-panda-tsx": () => import("./../../../src/pages/alternative/pull-panda.tsx" /* webpackChunkName: "component---src-pages-alternative-pull-panda-tsx" */),
  "component---src-pages-alternative-sleuth-tsx": () => import("./../../../src/pages/alternative/sleuth.tsx" /* webpackChunkName: "component---src-pages-alternative-sleuth-tsx" */),
  "component---src-pages-alternative-waydev-tsx": () => import("./../../../src/pages/alternative/waydev.tsx" /* webpackChunkName: "component---src-pages-alternative-waydev-tsx" */),
  "component---src-pages-benchmarks-tsx": () => import("./../../../src/pages/benchmarks.tsx" /* webpackChunkName: "component---src-pages-benchmarks-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-build-tsx": () => import("./../../../src/pages/build.tsx" /* webpackChunkName: "component---src-pages-build-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-cto-craft-con-tsx": () => import("./../../../src/pages/cto-craft-con.tsx" /* webpackChunkName: "component---src-pages-cto-craft-con-tsx" */),
  "component---src-pages-cto-dashboard-tsx": () => import("./../../../src/pages/cto-dashboard.tsx" /* webpackChunkName: "component---src-pages-cto-dashboard-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-developers-tsx": () => import("./../../../src/pages/developers.tsx" /* webpackChunkName: "component---src-pages-developers-tsx" */),
  "component---src-pages-devops-metrics-tsx": () => import("./../../../src/pages/devops-metrics.tsx" /* webpackChunkName: "component---src-pages-devops-metrics-tsx" */),
  "component---src-pages-dora-metrics-tsx": () => import("./../../../src/pages/dora-metrics.tsx" /* webpackChunkName: "component---src-pages-dora-metrics-tsx" */),
  "component---src-pages-dpa-tsx": () => import("./../../../src/pages/dpa.tsx" /* webpackChunkName: "component---src-pages-dpa-tsx" */),
  "component---src-pages-engineering-analytics-dashboard-tsx": () => import("./../../../src/pages/engineering-analytics-dashboard.tsx" /* webpackChunkName: "component---src-pages-engineering-analytics-dashboard-tsx" */),
  "component---src-pages-engineering-effectiveness-tsx": () => import("./../../../src/pages/engineering-effectiveness.tsx" /* webpackChunkName: "component---src-pages-engineering-effectiveness-tsx" */),
  "component---src-pages-engineering-management-platform-tsx": () => import("./../../../src/pages/engineering-management-platform.tsx" /* webpackChunkName: "component---src-pages-engineering-management-platform-tsx" */),
  "component---src-pages-enterprise-tsx": () => import("./../../../src/pages/enterprise.tsx" /* webpackChunkName: "component---src-pages-enterprise-tsx" */),
  "component---src-pages-events-engineering-metrics-us-tsx": () => import("./../../../src/pages/events/engineering-metrics-us.tsx" /* webpackChunkName: "component---src-pages-events-engineering-metrics-us-tsx" */),
  "component---src-pages-events-scaling-engineering-tsx": () => import("./../../../src/pages/events/scaling-engineering.tsx" /* webpackChunkName: "component---src-pages-events-scaling-engineering-tsx" */),
  "component---src-pages-git-analytics-tsx": () => import("./../../../src/pages/git-analytics.tsx" /* webpackChunkName: "component---src-pages-git-analytics-tsx" */),
  "component---src-pages-github-reporting-tsx": () => import("./../../../src/pages/github-reporting.tsx" /* webpackChunkName: "component---src-pages-github-reporting-tsx" */),
  "component---src-pages-github-slack-integration-tsx": () => import("./../../../src/pages/github-slack-integration.tsx" /* webpackChunkName: "component---src-pages-github-slack-integration-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jira-reporting-tsx": () => import("./../../../src/pages/jira-reporting.tsx" /* webpackChunkName: "component---src-pages-jira-reporting-tsx" */),
  "component---src-pages-noauth-enterprise-tsx": () => import("./../../../src/pages/noauth/enterprise.tsx" /* webpackChunkName: "component---src-pages-noauth-enterprise-tsx" */),
  "component---src-pages-noauth-index-tsx": () => import("./../../../src/pages/noauth/index.tsx" /* webpackChunkName: "component---src-pages-noauth-index-tsx" */),
  "component---src-pages-okr-agile-software-development-tsx": () => import("./../../../src/pages/okr-agile-software-development.tsx" /* webpackChunkName: "component---src-pages-okr-agile-software-development-tsx" */),
  "component---src-pages-pick-a-time-tsx": () => import("./../../../src/pages/pick-a-time.tsx" /* webpackChunkName: "component---src-pages-pick-a-time-tsx" */),
  "component---src-pages-pointer-io-tsx": () => import("./../../../src/pages/pointer-io.tsx" /* webpackChunkName: "component---src-pages-pointer-io-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-business-outcomes-tsx": () => import("./../../../src/pages/product/business-outcomes.tsx" /* webpackChunkName: "component---src-pages-product-business-outcomes-tsx" */),
  "component---src-pages-product-ci-cd-insights-tsx": () => import("./../../../src/pages/product/ci-cd-insights.tsx" /* webpackChunkName: "component---src-pages-product-ci-cd-insights-tsx" */),
  "component---src-pages-product-code-tsx": () => import("./../../../src/pages/product/code.tsx" /* webpackChunkName: "component---src-pages-product-code-tsx" */),
  "component---src-pages-product-data-platform-tsx": () => import("./../../../src/pages/product/data-platform.tsx" /* webpackChunkName: "component---src-pages-product-data-platform-tsx" */),
  "component---src-pages-product-developer-experience-tsx": () => import("./../../../src/pages/product/developer-experience.tsx" /* webpackChunkName: "component---src-pages-product-developer-experience-tsx" */),
  "component---src-pages-product-developer-overview-tsx": () => import("./../../../src/pages/product/developer-overview.tsx" /* webpackChunkName: "component---src-pages-product-developer-overview-tsx" */),
  "component---src-pages-product-developer-productivity-tsx": () => import("./../../../src/pages/product/developer-productivity.tsx" /* webpackChunkName: "component---src-pages-product-developer-productivity-tsx" */),
  "component---src-pages-product-engineering-metrics-tsx": () => import("./../../../src/pages/product/engineering-metrics.tsx" /* webpackChunkName: "component---src-pages-product-engineering-metrics-tsx" */),
  "component---src-pages-product-flow-tsx": () => import("./../../../src/pages/product/flow.tsx" /* webpackChunkName: "component---src-pages-product-flow-tsx" */),
  "component---src-pages-product-index-tsx": () => import("./../../../src/pages/product/index.tsx" /* webpackChunkName: "component---src-pages-product-index-tsx" */),
  "component---src-pages-product-initiatives-tsx": () => import("./../../../src/pages/product/initiatives.tsx" /* webpackChunkName: "component---src-pages-product-initiatives-tsx" */),
  "component---src-pages-product-investment-balance-tsx": () => import("./../../../src/pages/product/investment-balance.tsx" /* webpackChunkName: "component---src-pages-product-investment-balance-tsx" */),
  "component---src-pages-product-objections-tsx": () => import("./../../../src/pages/product/objections.tsx" /* webpackChunkName: "component---src-pages-product-objections-tsx" */),
  "component---src-pages-product-software-capitalization-tsx": () => import("./../../../src/pages/product/software-capitalization.tsx" /* webpackChunkName: "component---src-pages-product-software-capitalization-tsx" */),
  "component---src-pages-product-sred-sr-ed-scientific-research-experimental-development-tsx": () => import("./../../../src/pages/product/sred-sr-ed-scientific-research-experimental-development.tsx" /* webpackChunkName: "component---src-pages-product-sred-sr-ed-scientific-research-experimental-development-tsx" */),
  "component---src-pages-product-surveys-tsx": () => import("./../../../src/pages/product/surveys.tsx" /* webpackChunkName: "component---src-pages-product-surveys-tsx" */),
  "component---src-pages-product-tour-tsx": () => import("./../../../src/pages/product-tour.tsx" /* webpackChunkName: "component---src-pages-product-tour-tsx" */),
  "component---src-pages-product-visibility-tsx": () => import("./../../../src/pages/product/visibility.tsx" /* webpackChunkName: "component---src-pages-product-visibility-tsx" */),
  "component---src-pages-product-work-log-tsx": () => import("./../../../src/pages/product/work-log.tsx" /* webpackChunkName: "component---src-pages-product-work-log-tsx" */),
  "component---src-pages-product-working-agreements-tsx": () => import("./../../../src/pages/product/working-agreements.tsx" /* webpackChunkName: "component---src-pages-product-working-agreements-tsx" */),
  "component---src-pages-roi-tsx": () => import("./../../../src/pages/roi.tsx" /* webpackChunkName: "component---src-pages-roi-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-software-engineering-intelligence-platform-tsx": () => import("./../../../src/pages/software-engineering-intelligence-platform.tsx" /* webpackChunkName: "component---src-pages-software-engineering-intelligence-platform-tsx" */),
  "component---src-pages-software-engineering-kpis-tsx": () => import("./../../../src/pages/software-engineering-kpis.tsx" /* webpackChunkName: "component---src-pages-software-engineering-kpis-tsx" */),
  "component---src-pages-space-tsx": () => import("./../../../src/pages/space.tsx" /* webpackChunkName: "component---src-pages-space-tsx" */),
  "component---src-pages-startup-signup-tsx": () => import("./../../../src/pages/startup-signup.tsx" /* webpackChunkName: "component---src-pages-startup-signup-tsx" */),
  "component---src-pages-startups-tsx": () => import("./../../../src/pages/startups.tsx" /* webpackChunkName: "component---src-pages-startups-tsx" */),
  "component---src-pages-story-choco-tsx": () => import("./../../../src/pages/story/choco.tsx" /* webpackChunkName: "component---src-pages-story-choco-tsx" */),
  "component---src-pages-story-factorial-tsx": () => import("./../../../src/pages/story/factorial.tsx" /* webpackChunkName: "component---src-pages-story-factorial-tsx" */),
  "component---src-pages-story-factoryfix-tsx": () => import("./../../../src/pages/story/factoryfix.tsx" /* webpackChunkName: "component---src-pages-story-factoryfix-tsx" */),
  "component---src-pages-story-gorgias-tsx": () => import("./../../../src/pages/story/gorgias.tsx" /* webpackChunkName: "component---src-pages-story-gorgias-tsx" */),
  "component---src-pages-story-hostaway-tsx": () => import("./../../../src/pages/story/hostaway.tsx" /* webpackChunkName: "component---src-pages-story-hostaway-tsx" */),
  "component---src-pages-story-index-tsx": () => import("./../../../src/pages/story/index.tsx" /* webpackChunkName: "component---src-pages-story-index-tsx" */),
  "component---src-pages-story-miro-tsx": () => import("./../../../src/pages/story/miro.tsx" /* webpackChunkName: "component---src-pages-story-miro-tsx" */),
  "component---src-pages-story-pleo-tsx": () => import("./../../../src/pages/story/pleo.tsx" /* webpackChunkName: "component---src-pages-story-pleo-tsx" */),
  "component---src-pages-story-prima-tsx": () => import("./../../../src/pages/story/prima.tsx" /* webpackChunkName: "component---src-pages-story-prima-tsx" */),
  "component---src-pages-story-productsup-tsx": () => import("./../../../src/pages/story/productsup.tsx" /* webpackChunkName: "component---src-pages-story-productsup-tsx" */),
  "component---src-pages-story-skutopia-tsx": () => import("./../../../src/pages/story/skutopia.tsx" /* webpackChunkName: "component---src-pages-story-skutopia-tsx" */),
  "component---src-pages-story-truelayer-tsx": () => import("./../../../src/pages/story/truelayer.tsx" /* webpackChunkName: "component---src-pages-story-truelayer-tsx" */),
  "component---src-pages-subprocessors-tsx": () => import("./../../../src/pages/subprocessors.tsx" /* webpackChunkName: "component---src-pages-subprocessors-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-build-book-template-tsx": () => import("./../../../src/templates/buildBookTemplate.tsx" /* webpackChunkName: "component---src-templates-build-book-template-tsx" */),
  "component---src-templates-changelog-template-tsx": () => import("./../../../src/templates/changelogTemplate.tsx" /* webpackChunkName: "component---src-templates-changelog-template-tsx" */)
}

